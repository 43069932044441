import { useMemo, useEffect, useState } from 'react'
import { useTheme, useMediaQuery } from '@mui/material'
import { navigate } from 'gatsby'
import {
  PreinspectionSurveyUpdateCustomerDocument,
  PreinspectionSurveyCreateCustomerDocumentsDocument,
  Core_CustomerPreinspectionSurveyAnswersInput,
  Core_UpdateCustomerRequestInput,
  CustomerDocumentInfo,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { gql, useMutation } from '@apollo/client'
import { AnyInputConfig, InputType } from '@flock/shared-ui'

import { PreinspectionSurveyPageProps } from './preinspectionSurveyPageTypes'
import { CUSTOMER_OFFER_PAGE_PATH } from '../../routeConstants'

export const UPDATE_CUSTOMER = gql`
  mutation PreinspectionSurveyUpdateCustomer(
    $input: Core_UpdateCustomerRequestInput!
  ) {
    updateCustomer(input: $input) {
      _empty
    }
  }
`

export const CREATE_CUSTOMER_DOCUMENTS = gql`
  mutation PreinspectionSurveyCreateCustomerDocuments(
    $input: CreateCustomerDocumentsInput!
  ) {
    createCustomerDocuments(input: $input)
  }
`

const yesNoRadioSelectOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

// moves the label text over to the left, so it is better aligned.
// also increases the text size.
const textFieldLabelStyle = {
  sx: {
    '& .MuiInputLabel-shrink': {
      fontSize: '1.25rem',
      transform: 'translate(0, -6px) scale(1)',
    },
  },
}

const usePreinspectionSurveyPage = (props: PreinspectionSurveyPageProps) => {
  const { customerUuid } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  let innerContentMaxWidth = '1120px'
  if (isMobile) {
    innerContentMaxWidth = '311px'
  } else if (isTablet) {
    innerContentMaxWidth = '552px'
  }

  const [surveySubmitted, setSurveySubmitted] = useState<boolean>(false)
  const [fileUploadErrored, setFileUploadErrored] = useState<boolean>(false)
  const [answerSubmissionErrored, setAnswerSubmissionErrored] =
    useState<boolean>(false)

  const [updateCustomer, { loading: updateCustomerLoading }] = useMutation(
    PreinspectionSurveyUpdateCustomerDocument
  )

  const [createCustomerDocuments, { loading: createCustomerDocumentsLoading }] =
    useMutation(PreinspectionSurveyCreateCustomerDocumentsDocument)

  // after submitting the survey, we want to show a redirect page to let them know their survey was submitted properly.
  // Then we will redirect them back to the offer page after a few seconds.
  useEffect(() => {
    if (surveySubmitted) {
      window.scrollTo(0, 0)
      setTimeout(
        () => navigate(`${CUSTOMER_OFFER_PAGE_PATH}/${customerUuid}`),
        4500
      )
    }
  }, [surveySubmitted, customerUuid])

  let additionalInfoText = 'Please provide any additional helpful information.'
  if (isMobile) {
    additionalInfoText = 'Please provide any add. helpful info.'
  }

  const inputConfigs: AnyInputConfig[] = useMemo(
    () => [
      {
        name: 'openWorkOrders',
        type: InputType.RadioSelect,
        required: true,
        defaultValue: '',
        props: {
          label: 'Are there any open work orders or maintenance requests?',
          labelSize: 'medium',
          optionSize: 'p1',
          row: true,
          options: yesNoRadioSelectOptions,
        },
      },
      {
        name: 'openWorkOrdersDetails',
        type: InputType.Text,
        required: false,
        renderIf: (watchedFields: any) =>
          watchedFields.openWorkOrders === 'true',
        props: {
          label: 'Please describe details below.',
          ...textFieldLabelStyle,
        },
      },
      {
        name: 'hoaLeasingRestrictions',
        type: InputType.RadioSelect,
        required: true,
        defaultValue: '',
        props: {
          label:
            'Are there any HOA leasing restrictions applicable to the property(ies)?',
          labelSize: 'medium',
          optionSize: 'p1',
          row: true,
          options: yesNoRadioSelectOptions,
        },
      },
      {
        name: 'hoaLeasingRestrictionsDetails',
        type: InputType.Text,
        required: false,
        renderIf: (watchedFields: any) =>
          watchedFields.hoaLeasingRestrictions === 'true',
        props: {
          label: 'Please describe details below.',
          ...textFieldLabelStyle,
        },
      },
      {
        name: 'openInsuranceClaims',
        type: InputType.RadioSelect,
        required: true,
        defaultValue: '',
        props: {
          label: 'Are there any previous/open insurance claims?',
          labelSize: 'medium',
          optionSize: 'p1',
          row: true,
          options: yesNoRadioSelectOptions,
        },
      },
      {
        name: 'openInsuranceClaimsDetails',
        type: InputType.Text,
        required: false,
        renderIf: (watchedFields: any) =>
          watchedFields.openInsuranceClaims === 'true',
        props: {
          label: 'Please describe details below.',
          ...textFieldLabelStyle,
        },
      },
      {
        name: 'tenantOwnedAppliances',
        type: InputType.RadioSelect,
        required: true,
        defaultValue: '',
        props: {
          label: 'Are any appliances tenant owned?',
          labelSize: 'medium',
          optionSize: 'p1',
          row: true,
          options: yesNoRadioSelectOptions,
        },
      },
      {
        name: 'tenantOwnedAppliancesDetails',
        type: InputType.Text,
        required: false,
        renderIf: (watchedFields: any) =>
          watchedFields.tenantOwnedAppliances === 'true',
        props: {
          label: 'Please describe details below.',
          ...textFieldLabelStyle,
        },
      },
      {
        name: 'landlordUtilities',
        type: InputType.RadioSelect,
        required: true,
        defaultValue: '',
        props: {
          label: 'Are there any utilities in your name?',
          labelSize: 'medium',
          optionSize: 'p1',
          row: true,
          options: yesNoRadioSelectOptions,
        },
      },
      {
        name: 'landlordUtilitiesDetails',
        type: InputType.Text,
        required: false,
        renderIf: (watchedFields: any) =>
          watchedFields.landlordUtilities === 'true',
        props: {
          label: 'Please describe details below.',
          ...textFieldLabelStyle,
        },
      },
      {
        name: 'rentPaidOnTime',
        type: InputType.RadioSelect,
        required: true,
        defaultValue: '',
        props: {
          label: 'Has rent been paid on time for the last 3 months?',
          labelSize: 'medium',
          optionSize: 'p1',
          row: true,
          options: yesNoRadioSelectOptions,
        },
      },
      {
        name: 'rentPaidOnTimeDetails',
        type: InputType.Text,
        required: false,
        renderIf: (watchedFields: any) =>
          watchedFields.rentPaidOnTime === 'false',
        props: {
          label: 'Please describe details below.',
          ...textFieldLabelStyle,
        },
      },
      {
        name: 'rentPrepaid',
        type: InputType.RadioSelect,
        required: true,
        defaultValue: '',
        props: {
          label: 'Has any rent been prepaid?',
          labelSize: 'medium',
          optionSize: 'p1',
          row: true,
          options: yesNoRadioSelectOptions,
        },
      },
      {
        name: 'rentPrepaidDetails',
        type: InputType.Text,
        required: false,
        renderIf: (watchedFields: any) => watchedFields.rentPrepaid === 'true',
        props: {
          label: 'Please describe details below.',
          ...textFieldLabelStyle,
        },
      },
      {
        name: 'additionalDetails',
        type: InputType.Text,
        required: false,
        props: {
          label: additionalInfoText,
          multiline: true,
          minRows: 3,
          maxRows: 3,
          ...textFieldLabelStyle,
        },
      },
      {
        name: 'leaseFiles',
        type: InputType.FileUpload,
        required: false,
        props: {
          label:
            'Please upload current lease(s) if applicable along with any renewals/amendments.',
          labelVariant: 'p1',
        },
      },
    ],
    [additionalInfoText]
  )

  const onSubmitForm = async (answers: any) => {
    setFileUploadErrored(false)
    setAnswerSubmissionErrored(false)
    try {
      if (answers.leaseFiles && answers.leaseFiles.length > 0) {
        const documents: CustomerDocumentInfo[] = answers.leaseFiles.map(
          (file: any) => ({
            name: file.name,
            notes: 'submitted with pre-inspection survey',
            file,
          })
        )
        await createCustomerDocuments({
          variables: {
            input: {
              customerUuid,
              documents,
            },
          },
        })
      }
    } catch (e) {
      setFileUploadErrored(true)
      return
    }
    const preinspectionAnswersInput: Core_CustomerPreinspectionSurveyAnswersInput =
      {
        openWorkOrders: answers.openWorkOrders === 'true',
        openWorkOrdersDetails: answers.openWorkOrdersDetails || '',
        hoaLeasingRestrictions: answers.hoaLeasingRestrictions === 'true',
        hoaLeasingRestrictionsDetails:
          answers.hoaLeasingRestrictionsDetails || '',
        openInsuranceClaims: answers.openInsuranceClaims === 'true',
        openInsuranceClaimsDetails: answers.openInsuranceClaimsDetails || '',
        tenantOwnedAppliances: answers.tenantOwnedAppliances === 'true',
        tenantOwnedAppliancesDetails:
          answers.tenantOwnedAppliancesDetails || '',
        landlordUtilities: answers.landlordUtilities === 'true',
        landlordUtilitiesDetails: answers.landlordUtilitiesDetails || '',
        rentPaidOnTime: answers.rentPaidOnTime === 'true',
        rentPaidOnTimeDetails: answers.rentPaidOnTimeDetails || '',
        rentPrepaid: answers.rentPrepaid === 'true',
        rentPrepaidDetails: answers.rentPrepaidDetails || '',
        additionalDetails: answers.additionalDetails || '',
      }
    try {
      await updateCustomer({
        variables: {
          input: {
            customerUuid,
            preinspectionSurveyAnswers: preinspectionAnswersInput,
          } as Core_UpdateCustomerRequestInput,
        },
      })
      setSurveySubmitted(true)
    } catch (e) {
      setAnswerSubmissionErrored(true)
    }
  }

  return {
    inputConfigs,
    innerContentMaxWidth,
    surveySubmitted,
    updateCustomerLoading,
    createCustomerDocumentsLoading,
    onSubmitForm,
    fileUploadErrored,
    answerSubmissionErrored,
    isTablet,
  }
}

export default usePreinspectionSurveyPage
