import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { GridForm } from '@flock/shared-ui'

import { PreinspectionSurveyPageProps } from './preinspectionSurveyPageTypes'
import usePreinspectionSurveyPage from './usePreinspectionSurveyPage'

const PreinspectionSurvey = (props: PreinspectionSurveyPageProps) => {
  const {
    inputConfigs,
    innerContentMaxWidth,
    surveySubmitted,
    updateCustomerLoading,
    createCustomerDocumentsLoading,
    onSubmitForm,
    fileUploadErrored,
    answerSubmissionErrored,
    isTablet,
  } = usePreinspectionSurveyPage(props)
  if (surveySubmitted) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        minHeight="80vh"
        sx={{ backgroundColor: 'trustBlue.main', textAlign: 'center' }}
      >
        <Box maxWidth={innerContentMaxWidth} width="40%">
          <Typography variant="h3">
            Thank you for submitting your pre-inspection survey. You will now be
            redirected to your valuation page.
          </Typography>
        </Box>
      </Box>
    )
  } else {
    return (
      <Box
        py={{ xs: '16px', md: '32px', lg: '64px' }}
        px={{ xs: '32px', md: '96px', lg: '160px' }}
        display="flex"
        justifyContent="center"
        sx={{ backgroundColor: 'trustBlue.main' }}
      >
        <Box maxWidth={innerContentMaxWidth}>
          <Grid container columns={{ xs: 4, md: 6, lg: 12 }}>
            {!isTablet && <Grid item xs={0} lg={3} />}
            <Grid item xs={4} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap={!isTablet ? '64px' : '40px'}
              >
                <Typography variant="h2" color="gray8.main">
                  Pre-Inspection Survey
                </Typography>
                <Box display="flex" flexDirection="column" gap="8px">
                  <GridForm
                    onSubmit={onSubmitForm}
                    gridProps={{ gap: '32px' }}
                    inputConfigs={inputConfigs}
                    ctaText="Submit"
                    loading={
                      updateCustomerLoading || createCustomerDocumentsLoading
                    }
                  />
                  {fileUploadErrored && (
                    <Typography variant="p3" color="errorRed.main">
                      There was an error uploading your files. Please try again.
                      If it persists, reach out to Flock.
                    </Typography>
                  )}
                  {answerSubmissionErrored && (
                    <Typography variant="p3" color="errorRed.main">
                      There was an error submitting your answers. Please try
                      again. If it persists, reach out to Flock.
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
            {!isTablet && <Grid item lg={3} />}
          </Grid>
        </Box>
      </Box>
    )
  }
}

export default PreinspectionSurvey
