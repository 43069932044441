import React from 'react'
import PageWrapper from '../../components/SharedComponents/PageWrapper'
import PreinspectionSurvey from '../../components/PreinspectionSurveyComponents/PreinspectionSurveyPage'

type PreinspectionSurveyProps = {
  params: {
    customer: string
  }
}

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

const PreinspectionSurveyPage = (props: PreinspectionSurveyProps) => {
  const { params } = props
  const { customer: customerUuid } = params
  return (
    <PageWrapper
      title="Flock | Preinspection Survey"
      trackingName="preinspection-survey"
      headerBackground="trustBlue.main"
    >
      <PreinspectionSurvey customerUuid={customerUuid} />
    </PageWrapper>
  )
}

export default PreinspectionSurveyPage
